.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.clients2 .swiper-pagination {
  margin-top: 40px;
  position: relative;
}

.clients2 .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  opacity: 1;
  background-color: color-mix(in srgb, #e09145, transparent 80%);
}

.clients2 .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #e09145;
}

.icon_phone{
  position: absolute;
  background-color: #1AA126;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  top: 0;
  left: 50%;
  transform: translate(-50% , -50%);
}

.icon_phone{
  animation-name: phone_green;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in;
  transition: 500ms;
}


.icon_phone img {
  animation-name: trin;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@keyframes phone_green {

  from {
      transform:translate(-50%, -50%);
  }
  20% {
      transform:translate(-50%, -54%);
  }
  40% {
      transform:translate(-50%, -50%);
  }
  60% {
      transform:translate(-50%, -54%);
  }
  80% {
      transform:translate(-50%, -50%);
  }
  90% {
      transform:translate(-50%, -54%);
  }
  100% {
      transform:translate(-50%, -50%);
  }
}
